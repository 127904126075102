// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-cinebody-mdx": () => import("../src/pages/cinebody.mdx" /* webpackChunkName: "component---src-pages-cinebody-mdx" */),
  "component---src-pages-colophon-mdx": () => import("../src/pages/colophon.mdx" /* webpackChunkName: "component---src-pages-colophon-mdx" */),
  "component---src-pages-dig-colors-mdx": () => import("../src/pages/dig-colors.mdx" /* webpackChunkName: "component---src-pages-dig-colors-mdx" */),
  "component---src-pages-geosure-mdx": () => import("../src/pages/geosure.mdx" /* webpackChunkName: "component---src-pages-geosure-mdx" */),
  "component---src-pages-heartwood-mdx": () => import("../src/pages/heartwood.mdx" /* webpackChunkName: "component---src-pages-heartwood-mdx" */),
  "component---src-pages-spruce-labs-mdx": () => import("../src/pages/spruce-labs.mdx" /* webpackChunkName: "component---src-pages-spruce-labs-mdx" */),
  "component---src-pages-heartwood-process-mdx": () => import("../src/pages/heartwood-process.mdx" /* webpackChunkName: "component---src-pages-heartwood-process-mdx" */)
}

