import Helmet from 'react-helmet';
import CaseStudyIntro from "../../../../src/components/CaseStudyIntro/CaseStudyIntro";
import CaseStudyConclusion from "../../../../src/components/CaseStudyConclusion/CaseStudyConclusion";
import NextCaseStudy from "../../../../src/components/NextCaseStudy/NextCaseStudy";
import Wrapper from "../../../../src/components/Wrapper/Wrapper";
import Container from "../../../../src/components/Container/Container";
import SpruceLabsColorSection from "../../../../src/components/SpruceLabs/SpruceLabsColorSection";
import SpruceLabsWordsSection from "../../../../src/components/SpruceLabs/SpruceLabsWordsSection";
import heroImage from "../../../../src/images/Spruce-Labs-Hero@2x.png";
import shareImage from "../../../../src/images/Spruce-Labs-Thumb@2x.png";
import cinebodyThumb from "../../../../src/images/Cinebody-Thumb@2x.png";
import logoLoop from "../../../../src/pages/spruce-labs/Logo-Loop.gif";
import React from 'react';
export default {
  Helmet,
  CaseStudyIntro,
  CaseStudyConclusion,
  NextCaseStudy,
  Wrapper,
  Container,
  SpruceLabsColorSection,
  SpruceLabsWordsSection,
  heroImage,
  shareImage,
  cinebodyThumb,
  logoLoop,
  React
};