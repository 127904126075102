import Helmet from 'react-helmet';
import CaseStudyIntro from "../../../../src/components/CaseStudyIntro/CaseStudyIntro";
import CaseStudyConclusion from "../../../../src/components/CaseStudyConclusion/CaseStudyConclusion";
import NextCaseStudy from "../../../../src/components/NextCaseStudy/NextCaseStudy";
import heroImage from "../../../../src/images/Cinebody-Hero@2x.png";
import shareImage from "../../../../src/images/Cinebody-Thumb@2x.png";
import geosureThumb from "../../../../src/images/GeoSure-Thumb@2x.png";
import React from 'react';
export default {
  Helmet,
  CaseStudyIntro,
  CaseStudyConclusion,
  NextCaseStudy,
  heroImage,
  shareImage,
  geosureThumb,
  React
};