import Helmet from 'react-helmet';
import CaseStudyIntro from "../../../../src/components/CaseStudyIntro/CaseStudyIntro";
import CaseStudyConclusion from "../../../../src/components/CaseStudyConclusion/CaseStudyConclusion";
import NextCaseStudy from "../../../../src/components/NextCaseStudy/NextCaseStudy";
import AutoplayVideo from "../../../../src/components/AutoplayVideo/AutoplayVideo";
import Wrapper from "../../../../src/components/Wrapper/Wrapper";
import Container from "../../../../src/components/Container/Container";
import heroImage from "../../../../src/images/GeoSure-Hero@2x.png";
import dayNightVid from "../../../../src/pages/geosure/GeoSure-DayNight.mp4";
import shareImage from "../../../../src/images/Cinebody-Thumb@2x.png";
import digColorsThumb from "../../../../src/images/DIG-Colors-Thumb@2x.png";
import React from 'react';
export default {
  Helmet,
  CaseStudyIntro,
  CaseStudyConclusion,
  NextCaseStudy,
  AutoplayVideo,
  Wrapper,
  Container,
  heroImage,
  dayNightVid,
  shareImage,
  digColorsThumb,
  React
};