module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"tinyeahno-2020","short_name":"tinyeahno","start_url":"/","background_color":"#FFD580","theme_color":"#FFD580","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"quality":80,"maxWidth":1200,"linkImagesToOriginal":false,"sizeByPixelDensity":false,"showCaptions":true,"markdownCaptions":true,"withWebp":true}}],"defaultLayouts":{"default":"/opt/build/repo/src/components/CaseStudyLayout/CaseStudyLayout.tsx"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-122652934-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
