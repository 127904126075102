import Helmet from 'react-helmet';
import CaseStudyIntro from "../../../../src/components/CaseStudyIntro/CaseStudyIntro";
import CaseStudyConclusion from "../../../../src/components/CaseStudyConclusion/CaseStudyConclusion";
import NextCaseStudy from "../../../../src/components/NextCaseStudy/NextCaseStudy";
import heroImage from "../../../../src/images/Heartwood-Hero@2x.png";
import shareImage from "../../../../src/images/Heartwood-Thumb@2x.png";
import spruceLabsThumb from "../../../../src/images/Spruce-Labs-Thumb@2x.png";
import React from 'react';
export default {
  Helmet,
  CaseStudyIntro,
  CaseStudyConclusion,
  NextCaseStudy,
  heroImage,
  shareImage,
  spruceLabsThumb,
  React
};